import Account from '~/account/Account';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { LocalStorageHelper } from '~/utils/storage';
import { DWH_EVENTS } from '~/const';
import dwhExport from '~/api/dwhExport';
import { isEmptyObject, isInGameBrowser } from '~/utils/utils';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import store from '~/Store';

export const isEnabledSoundsByCategory = (category: ICategory, thirdPartySoundMode = true) => {
    if (category?.videoBackground?.hasSound) {
        return true;
    }

    const hasMusic = !isEmptyObject(category?.audioTrack);
    if (!hasMusic) {
        return false;
    }

    return thirdPartySoundMode ? true : category.audioTrack.isFreeLicense;
};

export const isEnabledSoundsByBundle = (bundle: IBundle, thirdPartySoundMode = true) => {
    if (bundle?.videoBackground?.hasSound) {
        return true;
    }

    const hasMusic = !isEmptyObject(bundle?.audioTrack);
    if (!hasMusic) {
        return false;
    }

    return thirdPartySoundMode ? true : bundle.audioTrack.isFreeLicense;
};

export const isEnabledSoundsByBundleFullscreenGallery = (bundle: IBundle) => {
    if (bundle?.isFullscreenGallery && bundle.gallery?.find((s) => s.video?.hasSound)) {
        return true;
    }
    return false;
};

export const getSoundKeyForCurrentPage = (category: ICategory, bundle: IBundle) => {
    const { clientSettings } = store.getState().account;

    if (category?.theme === SANTA_PAGE_THEME) {
        return SANTA_PAGE_THEME;
    }

    if (isEnabledSoundsByBundle(bundle, clientSettings?.settings?.third_party_sounds_mode)) {
        return bundle.name;
    }

    if (isEnabledSoundsByCategory(category, clientSettings?.settings?.third_party_sounds_mode)) {
        return category.name;
    }

    if (isEnabledSoundsByBundleFullscreenGallery(bundle)) {
        return `gallery_${bundle.name}`;
    }

    return null;
};

export const isEnabledSoundsForCurrentPage = (category: ICategory, bundle: IBundle) => {
    return isEnabledSoundsByCategory(category) || isEnabledSoundsByBundle(bundle);
};

export const isAvailableSoundByKey = (soundKey: string) => {
    return LocalStorageHelper.get(Account.getLSKey(`audio:${soundKey}`)) !== false;
};

export const updateSoundValueByKey = (soundKey: string, isEnabled: boolean) => {
    LocalStorageHelper.set(Account.getLSKey(`audio:${soundKey}`), isEnabled);
};

export const hasSoundSettingByCategory = (soundKey: string) => {
    if (isMobileOrTabletDeviceByAgent()) {
        return true;
    }

    return isAvailableSoundByKey(soundKey);
};

export const changeVolumeSoundByKey = (soundKey: string, isEnabled: boolean, isBundle = false) => {
    updateSoundValueByKey(soundKey, isEnabled);
    dwhExport.send(DWH_EVENTS.CHANGE_SOUND_STATUS, {
        [!isBundle ? 'category_name' : 'bundle_name']: soundKey,
        status: isEnabled ? 'on' : 'off',
    });
};

export const isEnabledSounds = (soundKey: string) => {
    if (isMobileOrTabletDeviceByAgent()) {
        return false;
    }

    return isAvailableSoundByKey(soundKey);
};

export const getDefaultValueBySoundKey = (soundKey: string, isTrusted: boolean) => {
    if (!soundKey || (!isInGameBrowser && !isTrusted)) {
        return false;
    }

    return isEnabledSounds(soundKey);
};
