import styles from './LootboxRewardItemCard.scss';
import { CrewTypes, items, PresentationStyles } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { isInGameBrowser } from '~/utils/utils';
import Account from '~/account/Account';
import { openPortByItem, type IOpenPortByItem } from '~/settings/port';
import { PortPreviewType } from '../Port/settings';
import { useDispatch } from 'react-redux';
import { playCrewRandomVOSample } from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import { appActions, HIDE_ALL_POPUPS } from '~/Store/appSlice';
import { POPUPS_NAME } from '../PopupManager';

interface IItemCard {
    reward: ILootboxReward;
    className?: string;
    inventory?: InventoryState;
    hideInventoryWidget?: boolean;
    isUniqueGroup?: boolean;
    bundleId: number;
    lootboxId: number;
    goToPortHandler?: () => void;
    isfromCrewGroup?: boolean;
    autoDescriptionSettings?: ILootboxAutoDescriptionSettings;
    onRender?: () => void;
}

const LootboxRewardItemCard = ({ reward, isUniqueGroup, bundleId, goToPortHandler, lootboxId, autoDescriptionSettings, className, isfromCrewGroup, onRender }: IItemCard) => {
    const dispatch = useDispatch();
    const { additionalData } = reward;
    const [isExistsInInventory, inventoryCount] = Account.getInventoryInfoByType(reward.type as keyof Inventory, reward.id, reward.shipId);
    const isEnabledPortPreview = isInGameBrowser && ([items.VEHICLES, items.PERMOFLAGES, items.SKIN, items.STYLE, items.MSKIN] as string[]).includes(reward.type);

    let crewVoiceId = reward.id;
    const hasSampleVo = (reward.additionalData?.hasSampleVo || reward.additionalData?.defaultCrew?.hasSampleVo) && isInGameBrowser;
    if (reward.additionalData?.defaultCrew?.hasSampleVo) {
        crewVoiceId = reward.defaultCrew;
    }

    const isNotUniqueReward = !isUniqueGroup;

    const goToPreview = () => {
        window?.tooltipProvider?.hide?.();

        dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));

        const params: IOpenPortByItem = Object.assign({
            item: {
                shipId: +additionalData.ship?.id,
                type: reward.type,
                identifier: reward.id,
            },
            additionalData: { lootboxId },
            portPreviewType: PortPreviewType.CONTAINER,
            bundleId,
        });

        if (autoDescriptionSettings?.openPortByItemParams) {
            Object.assign(params, autoDescriptionSettings.openPortByItemParams);
        }

        openPortByItem(params);
        goToPortHandler?.();
    };

    const showAutodescriptionPopup = () => {
        if (reward.type !== items.LOOTBOX) {
            return;
        }
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.ITEM_DESCRIPTION_POPUP,
                data: {
                    item: {
                        identifier: reward.id,
                        type: reward.type,
                    },
                    autoDescriptionSettings: {},
                },
            }),
        );
    };

    const classesItem = classNames(
        styles.item,
        {
            [styles.crew]: isfromCrewGroup,
        },
        className,
    );

    return (
        <div className={classesItem} onClick={showAutodescriptionPopup}>
            <WoWSEntity
                type={reward.type}
                id={reward.id}
                onRender={onRender}
                presentation={{
                    style: PresentationStyles.CARD,
                    withTooltip: true,
                    isAvailablePortPreview: isEnabledPortPreview,
                    isAvailableCrewVoicePrevew: hasSampleVo,
                    renderWithoutFade: true,
                }}
                customisation={{
                    shipId: reward.shipId,
                    crewId: reward.defaultCrew && reward.additionalData?.defaultCrew?.type !== CrewTypes.COMMON ? reward.defaultCrew : null,
                    points: reward.crewLevel,
                    isExistsInInventory: !isNotUniqueReward && isExistsInInventory,
                    inventoryCount: isNotUniqueReward ? inventoryCount : 0,
                }}
                onClick={{
                    onPortPreview: goToPreview,
                    onCrewVoicePreview: () => {
                        const crewId = reward.type === items.CREWS ? reward.id : reward.defaultCrew;
                        playCrewRandomVOSample(+crewId);
                    },
                }}
            />
        </div>
    );
};

export default LootboxRewardItemCard;
