import * as React from 'react';
import styles from './View.scss';
import { MenuWrapper } from '~/components/Menu/Menu';
import History from '~/utils/history';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageHeader from '~/components/PageHeader/PageHeader';
import { scrollToBundleIfNeeded } from '~/utils/scrollTop';
import PopupManager from '~/components/PopupManager';
import classNames from 'classnames';
import { Processing } from '@wg/wows-react-uikit';
import NotificationManager, { getNotification, getNotificationToShowIfNeeded } from '~/components/Notifications/NotificationManager';
import VideoPlayer from '~/components/VideoPlayer/VideoPlayer';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import Footer from '~/components/Footer/Footer';
import useScroll from '~/hooks/useScroll';
import MobileMenuFooter from '~/components/Footer/MobileMenuFooter';
import ViewBackground from '~/components/ViewBackground/ViewBackground';
import GiftPromo from '~/components/GiftPromo/GiftPromo';
import DevModePanel from '~/components/DevModePanel/DevModePanel';
import RewardPopupManager from '~/components/Popups/Reward/RewardPopupManager';
import ViewTopPanel from '~/components/View/ViewTopPanel';
import ScrollToTopButton from '~/components/ScrollToTopButton/ScrollToTopButton';
import RefManager, { RefManagerKeys } from '~/RefManager';
import CategoryFilter from '~/components/CategoryFilter/CategoryFilter';
import { isNeedToRenderFilter, parseFiltersInQueryParams } from '~/utils/filters';
import { useDispatch } from 'react-redux';
import { isDockyardCategory } from '~/utils/category';
import useMobile from '~/hooks/useMobile';
import usePlayMainTheme from '~/hooks/usePlayMainTheme';
import { FilterPosition } from '~/types/category';
import { showPurchasePopupFromSearchParamsIfNeeded } from '~/utils/bundles';
import { applySortByQueryParams } from '~/utils/sort/settings';
import StickyBox from 'react-sticky-box';
import CategorySkeleton from '~/components/CategorySkeleton/CategorySkeleton';
import { appActions } from '~/Store/appSlice';
import { viewStateSelector } from '~/containers/ViewContainer/ViewContainer';
import { NotificationDisplay } from '~/types/notifications';
import { isValidDate } from '~/utils/time';
import useClosePopupAfterChangingHistory from '~/hooks/useClosePopupAfterChangingHistory';

export type OutletContextType = {
    isBundlePage?: boolean;
};

export const parseQueryParams = () => {
    showPurchasePopupFromSearchParamsIfNeeded();
    parseFiltersInQueryParams();
    applySortByQueryParams();
};

const View = (props: ReturnType<typeof viewStateSelector>): React.ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLDivElement>(null);
    const mainContainer = React.useRef<HTMLDivElement>(null);

    const [isMobile] = useMobile();
    const isHiddenMobileNav = props.isHiddenMobileNav && isMobile;

    const isVisibleTopPanel = props.isVisibleTopPanel && !isHiddenMobileNav;
    const isVisibleMenu = props.isVisibleMenu && !props.port.isVisible;

    const isVisiblePopup = !!props.popupActive;
    const availableInfoScreenName = getNotificationToShowIfNeeded();
    const infoScreenName = !isVisiblePopup && !props.port.isVisible && !props.clientSource ? availableInfoScreenName ?? props.currentNotificationVisibleName : null;
    const isInfoScreenPopup = infoScreenName && getNotification(infoScreenName)?.display === NotificationDisplay.POPUP;
    const isBlur = (isVisiblePopup || isInfoScreenPopup || props.isVisibleRewardPopup) && !props.popupActive?.data?.isNoBlur;
    const isVisibleAnyMorePopups = !!(isVisiblePopup || infoScreenName);

    const [isVisibleFilters, setFiltersVisible] = React.useState(false);
    const currentCategory = props.categories?.[props.currentPage?.name];

    const _isDockyardCategory = isDockyardCategory();

    const isAvailablePromoTimer = currentCategory && currentCategory.promoTimer && isValidDate(currentCategory.promoTimer.timerActiveTill);
    const isNeedToRenderRightFilters = !isAvailablePromoTimer && isNeedToRenderFilter(props.currentPage?.name) && !props.currentPage?.isBundlePage && props.filterPosition === FilterPosition.RIGHT;

    const viewClassNames = classNames(styles.view, {
        [styles.blur]: (!_isDockyardCategory && props.isBlurView) || isBlur,
        [styles.zIndex]: isMobileOrTabletWindow && isVisibleFilters,
        [styles.mobileBlur]: !_isDockyardCategory && isMobileOrTabletWindow && props.isBlurView,
        [styles.fullScreen]: props.isFullscreen,
        [`armory-theme__${currentCategory?.theme}`]: !!currentCategory?.theme,
    });

    const mobileComponentClasses = classNames({ [styles.blur]: isBlur });

    const mainContainerClassNames = classNames(styles.mainContainer, { [styles.hideScrollBar]: isInGameBrowser && isNeedToRenderRightFilters });

    const popupWrapperClassNames = classNames(styles.popupWrapper, {
        [styles.inPort]: props.port?.isVisible,
    });

    useScroll(
        mainContainer,
        () => {
            ref.current?.classList.add(styles.scrolled);
        },
        () => {
            ref.current?.classList.remove(styles.scrolled);
        },
    );

    usePlayMainTheme();

    useClosePopupAfterChangingHistory();

    React.useEffect(() => {
        History.initRouterNavigate(navigate);
    }, [navigate]);

    React.useEffect(() => {
        History.addToHistory(location.pathname);
    }, [location.pathname]);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            window.addEventListener('popstate', () => {
                setFiltersVisible(false);
            });
        }

        const onClickBody = () => {
            dispatch(appActions.setTrusted());
            document.removeEventListener('click', onClickBody);
        };
        document.addEventListener('click', onClickBody);
    }, []);

    React.useEffect(() => {
        if (props.isFinishedRequest) {
            parseQueryParams();
            scrollToBundleIfNeeded();
        }
    }, [props.isFinishedRequest]);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.MainContainer, _ref);
            mainContainer.current = _ref;
        }
    };

    return (
        <React.Fragment>
            {!props.port?.isVisible && <ViewBackground isBlur={isBlur || props.isBlurView} />}
            {isMobileOrTabletWindow && !isHiddenMobileNav && (
                <div className={mobileComponentClasses}>
                    <PageHeader />
                </div>
            )}
            <div className={viewClassNames}>
                {!isMobileOrTabletWindow && <PageHeader />}
                <div className={styles.menuWrapper}>{isVisibleMenu && <MenuWrapper showSkeleton={!props.isFinishedRequest} />}</div>
                <div className={styles.contentWrapper}>
                    {isVisibleTopPanel && (
                        <ViewTopPanel
                            {...props}
                            onChangeFilterVisible={(visible) => {
                                setFiltersVisible(visible);
                            }}
                        />
                    )}
                    <div className={mainContainerClassNames} ref={setRef}>
                        <div className={styles.content}>
                            <div className={styles.leftContent} />
                            <div className={styles.mainContent} ref={ref}>
                                {props.isFinishedRequest}
                                {!props.isFinishedRequest ? (
                                    <CategorySkeleton />
                                ) : (
                                    <Outlet
                                        context={
                                            {
                                                isBundlePage: props.currentPage?.isBundlePage,
                                            } satisfies OutletContextType
                                        }
                                    />
                                )}
                            </div>
                            <div className={styles.rightContent}>
                                {isNeedToRenderRightFilters && (
                                    <StickyBox>
                                        <CategoryFilter category={props.currentPage?.name} withoutActiveFilters={true} />
                                    </StickyBox>
                                )}
                            </div>
                        </div>
                        {!isHiddenMobileNav && !props.port?.isVisible && <Footer />}
                        <ScrollToTopButton currentPage={props.currentPage} />
                    </div>
                </div>
            </div>
            {!isHiddenMobileNav && (
                <div className={mobileComponentClasses}>
                    <MobileMenuFooter />
                </div>
            )}
            {isVisiblePopup && (
                <div className={popupWrapperClassNames}>
                    {props.popups.map((popup: IPopup, index: number) => {
                        return (
                            <div className={styles.popupItemWrapper} key={`${popup.name}_${index}`}>
                                <PopupManager popup={popup} isPopupActive={index === props.popups.length - 1} />
                            </div>
                        );
                    })}
                </div>
            )}
            {infoScreenName && <NotificationManager name={infoScreenName.toString()} notifications={props.notifications} />}
            {props.isFetching && <Processing isFetching />}
            {!isVisibleAnyMorePopups && props.isGiftAvailable && !props.port?.isVisible && <GiftPromo />}
            {!infoScreenName && <VideoPlayer />}
            {!isVisibleAnyMorePopups && !props.isGiftAvailable && <RewardPopupManager />}
            <DevModePanel />
        </React.Fragment>
    );
};

export default View;
