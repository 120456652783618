import { useEffect } from 'react';
import store from '~/Store';
import { HIDE_ALL_POPUPS, appActions } from '~/Store/appSlice';

const useClosePopupAfterChangingHistory = () => {
    useEffect(() => {
        window.addEventListener('popstate', () => {
            const popups = store.getState().app.popups;
            if (!!popups?.length) {
                store.dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
            }
        });
    }, []);
};

export default useClosePopupAfterChangingHistory;
