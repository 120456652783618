import { isEmptyObject, isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { hasAnimationSetting, isDisabledAnimationByName, updateAnimationStatusByName } from '~/utils/animations/settings';
import equal from 'fast-deep-equal/react';
import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getClientSettings';
import React from 'react';
import { RootState, useAppSelector } from '~/Store';

const hookStateSelector = (state: RootState) => {
    return {
        clientSettings: state.account.clientSettings,
        currentPage: state.app.currentPage,
    };
};

export const useDisabledAnimationByGraphics = (name: string) => {
    const { clientSettings, currentPage } = useAppSelector(hookStateSelector, equal);

    React.useEffect(() => {
        if (!name || hasAnimationSetting(name) || !isInGameBrowser || isEmptyObject(clientSettings)) {
            return;
        }
        if (clientSettings.preset === UserGraphicsQualityPresetTypes.LOW && name) {
            updateAnimationStatusByName(name, true);
        }
    }, [name, currentPage?.name, clientSettings]);
};

export default function (name: string, isDisabledMobileAnimation: boolean = isMobileOrTabletWindow) {
    const categoriesAnimationStatuses = useAppSelector((state) => state.app.categoriesAnimationStatuses, equal);
    return isDisabledMobileAnimation || isDisabledAnimationByName(name) || !!categoriesAnimationStatuses?.[name];
}
