import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getClientSettings';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { SANTA_CONFIRM_PURCHASE_VIDEO, SANTA_SIZE, isGoldShowcase } from '~/Layouts/Themes/SantaPage/settings';
import styles from './SantaCustomization.scss';
import { isChina } from '~/utils/settings';
import { RootState, useAppSelector } from '~/Store';

interface ISantaCustomization {
    children: React.ReactNode;
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        clientSettings: state.account.clientSettings,
        currentPage: state.app.currentPage,
    };
};

const SantaCustomization = ({ children, bundle }: ISantaCustomization) => {
    const state = useAppSelector(stateSelector, equal);
    const isLowGraphics = state.clientSettings?.preset === UserGraphicsQualityPresetTypes.LOW;
    const _isGoldShowcase = isGoldShowcase(state.currentPage?.name);
    const videoMap = isChina() && !_isGoldShowcase ? SANTA_CONFIRM_PURCHASE_VIDEO.cn : SANTA_CONFIRM_PURCHASE_VIDEO.global;

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <VideoBackground
                    poster={bundle.icons.confirmationBackground}
                    video={isLowGraphics ? null : videoMap[SANTA_SIZE[bundle.groupName]]}
                    className={styles.background}
                    posterClassName={styles.poster}
                    videoClassName={styles.videoBackground}
                    withMask={true}
                    maskClassName={styles.mask}
                />
            </div>
            <>{children}</>
        </React.Fragment>
    );
};

export default SantaCustomization;
