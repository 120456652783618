import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Account from '~/account/Account';
import { getProgressiveDiscountFromBundle, getSinglePurchaseMaxQuantity } from '~/utils/bundles';
import { items as ITEMS, assets as ASSETS } from '@wg/wows-entities/const';
import Quantity, { IQuantityTooltips } from '~/components/Quantity/Quantity';
import { calcMaxQuantityByBalance, calculateBundlePrices, isQuantityIncrementOverdraftAvailable } from '~/utils/purchase';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IQuantityContainer {
    bundle: IBundle;
    offersLimit?: number;
    fromPopup?: boolean;
    tooltips?: IQuantityTooltips;
    idDisabled?: boolean;
    onUpdateHandler?: (n: number) => void;
}

const stateSelector = (state: RootState) => {
    return {
        activeCouponId: state.account.activeCouponId,
        coupons: state.account.coupons,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        balance: state.account.balance,
    };
};

export const ALLOWED_LIST_WITH_MANY_ITEMS: string[] = [ITEMS.SIGNAL, ITEMS.CAMOUFLAGE, ITEMS.LOOTBOX, ITEMS.CAMO_BOOST, ITEMS.MULTI_BOOST];

const QuantityContainer = ({ bundle, offersLimit, fromPopup, tooltips, idDisabled, onUpdateHandler }: IQuantityContainer) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);
    const purchasedLimitedBundle = state.purchasedLimitedBundles[bundle.id] || 0;
    const primaryItem = bundle.primaryItem;
    let personalLimit = offersLimit || bundle.limitedQuantity?.personalLimit;
    personalLimit = personalLimit > 0 && purchasedLimitedBundle ? personalLimit - purchasedLimitedBundle : null;
    const singleLimit = bundle.singlePurchaseMaxQuantity || getSinglePurchaseMaxQuantity();
    let bundleLimit = personalLimit > singleLimit || !personalLimit ? singleLimit : personalLimit;

    const isEnabledIncrementByAmount = primaryItem && ALLOWED_LIST_WITH_MANY_ITEMS.includes(primaryItem.type);
    const isEditable = !(primaryItem?.amount > 1 && !(Object.values(ASSETS) as string[]).includes(primaryItem?.type));

    const limitByBalance = calcMaxQuantityByBalance(bundle, state.balance);
    if (limitByBalance < bundleLimit) {
        bundleLimit = limitByBalance;
    }

    React.useEffect(() => {
        return () => {
            if (!fromPopup) {
                dispatch(appActions.updateQuantity({ bundleId: bundle.id }));
            }
        };
    }, []);

    const timeoutId = React.useRef<NodeJS.Timeout>(null);

    function onUpdate(value: number) {
        clearTimeout(timeoutId.current);
        const prices = calculateBundlePrices(bundle, state.balance, value, activeCoupon?.discount, getProgressiveDiscountFromBundle(bundle, value));
        timeoutId.current = setTimeout(() => {
            const _quantity = bundle.quantityData?.quantity;
            if (_quantity !== +value) {
                dispatch(appActions.updateQuantity({ bundleId: bundle.id, quantity: value, quantityData: prices }));
            }
        }, 45);
        onUpdateHandler?.(value);
    }

    function checkAbilityIncrement(quantity: number) {
        const prices = calculateBundlePrices(bundle, state.balance, quantity, activeCoupon?.discount, getProgressiveDiscountFromBundle(bundle, quantity));
        if (Account.canIncrementQuantity(prices, state.balance, bundle, activeCoupon?.discount)) {
            return true;
        }
        return isQuantityIncrementOverdraftAvailable(prices);
    }

    const step = isEnabledIncrementByAmount ? primaryItem?.amount : 1;

    return (
        <Quantity
            checkAbilityIncrement={checkAbilityIncrement}
            max={bundleLimit * step}
            isEditable={isEditable}
            step={step}
            onUpdate={onUpdate}
            isAllowedFirstIncrement={checkAbilityIncrement(2)}
            value={bundle.quantityData?.quantity}
            isDisabled={idDisabled || !!state.activeCouponId}
            tooltips={{
                mainTooltip: state.activeCouponId ? <DefaultTooltip text={t('Чтобы изменить количество товара, удалите купон')} /> : null,
                ...(tooltips || {}),
            }}
        />
    );
};

export default QuantityContainer;
