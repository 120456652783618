import { RootState } from '~/Store';

export const routingStateSelector = (state: RootState) => {
    return {
        bundles: state.app.bundles,
        categories: state.app.categories,
        menu: state.app.menu,
        currentPage: state.app.currentPage,
    };
};
