import * as React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { openBundleByUrl } from '~/utils/category';
import classNames from 'classnames';
import styles from './SantaBundle.scss';
import BundleAmount from '~/components/BundleAmount/BundleAmount';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import { DEFAULT_SELECTED_INDEX_BY_GROUP, isGoldShowcase, SANTA_BUNDLES_AUDIO_MAP, SANTA_BUNDLE_CARD_VIDEOS, SANTA_SIZE } from '~/Layouts/Themes/SantaPage/settings';
import { getStaticUrl, isInGameBrowser, isMobileOrTabletWindow, redirectToLogin } from '~/utils/utils';
import { Howl } from 'howler';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import useResourceLoader from '~/hooks/useResourceLoader';
import { getSupportedVideo, isVideoTypeSupported } from '~/utils/video';
import { groupBundlesByName } from '~/utils/bundles';
import isDisabledAnimation from '~/hooks/isDisabledAnimation';
import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getClientSettings';
import preCacheImage from '~/utils/preCacheImage';
import RealPurchaseButton from '~/components/Button/RealPurchaseButton';
import { isChina, isSafariBrowser } from '~/utils/settings';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { interpolate, ngettext, t } from '~/utils/localization';
import AnimatedNumber from '~/components/AnimatedNumber/AnimatedNumber';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import Attention from '~/components/Attention/Attention';
import { items } from '@wg/wows-entities/const';
import Account from '~/account/Account';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface ISantaBundle {
    groupName: string;
    groups: ReturnType<typeof groupBundlesByName>;
    isLastGroup: boolean;
    isDisabledAnimation?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        groupBundles: state.app.groupBundles,
        categoriesAnimationStatuses: state.app.categoriesAnimationStatuses,
        clientSettings: state.account.clientSettings,
        categories: state.app.categories,
        bundles: state.app.bundles,
    };
};

function getContainerIdleImage(size: string, isChina = false, isRealMoneyCategory = false) {
    if (isChina && isRealMoneyCategory) {
        return getStaticUrl(require(`./images/static_box_idle_${size}_cn.png`));
    }
    return getStaticUrl(require(`./images/static_box_idle_${size}.png`));
}

function getContainerHoverImage(size: string, isChina = false, isRealMoneyCategory = false) {
    if (isChina && isRealMoneyCategory) {
        return getStaticUrl(require(`./images/static_box_hover_${size}_cn.png`));
    }
    return getStaticUrl(require(`./images/static_box_hover_${size}.png`));
}

interface VideoElement {
    src: string;
    className?: string;
    autoPlay?: boolean;
}

const VideoElement = React.memo(
    React.forwardRef(({ className, src, autoPlay }: VideoElement, ref: React.RefObject<HTMLVideoElement>) => {
        return <video className={className} preload={'auto'} autoPlay={autoPlay} muted loop src={src} ref={ref} />;
    }),
);

const SantaBundle = React.forwardRef((props: ISantaBundle, ref: React.RefObject<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, shallowEqual);
    const selectedBundleStore = state.groupBundles || {};
    const currentGroupsBundles: IBundle[] = props.groups[props.groupName];
    const isCn = isChina();
    let _isDisabledAnimation = isDisabledAnimation(SANTA_PAGE_THEME);
    if (!isVideoTypeSupported('webm') || isSafariBrowser) {
        _isDisabledAnimation = true;
    }
    const idleAnimationRef = React.useRef<HTMLVideoElement>(null);
    const hoverAnimationRef = React.useRef<HTMLVideoElement>(null);
    const SIZE = SANTA_SIZE[props.groupName];
    const howl = new Howl({ src: SANTA_BUNDLES_AUDIO_MAP[SIZE] });
    const isAvailableSoundEffect = useAvailableSound(state.categories[state.currentPage?.name], null);
    const _isGoldShowcase = isGoldShowcase(state.currentPage?.name);
    const animationsMap = isCn && !_isGoldShowcase ? SANTA_BUNDLE_CARD_VIDEOS.cn[SIZE] : SANTA_BUNDLE_CARD_VIDEOS.global[SIZE];
    const isHiddenProductsInfo = isCn && !_isGoldShowcase && !Account.getAccount()?.id;

    const selectedBundleIndex = React.useMemo(() => {
        return currentGroupsBundles?.reduce((state: number, bundle: IBundle, index: number) => {
            if (bundle.id === selectedBundleStore?.[props.groupName]) {
                state = index;
            }
            return state;
        }, null);
    }, [props.groupName]);

    let currentBundle: IBundle;

    if (selectedBundleIndex !== null && selectedBundleIndex !== undefined) {
        currentBundle = currentGroupsBundles[selectedBundleIndex];
    } else {
        currentBundle = currentGroupsBundles[DEFAULT_SELECTED_INDEX_BY_GROUP[props.groupName] || 0] || currentGroupsBundles[0];
    }

    const [selectedBundle, updateSelectedBundle] = React.useState(currentBundle);
    const [isLoadingResources, resourcesMap] = useResourceLoader([animationsMap.idle, animationsMap.hover], isInGameBrowser);

    const supportedIntroVideoUrl = getSupportedVideo(selectedBundle.entryVideo);
    const [isLoadingIntroBundleVideo, resourceIntroVideo] = useResourceLoader([supportedIntroVideoUrl], isInGameBrowser);

    if (!selectedBundle) {
        return null;
    }

    const eventumAmount = selectedBundle.entitlements.find((item) => item.type !== items.LOOTBOX)?.amount || 0;
    const VIEWED_VIDEO_KEY_LS = `santa_bundle_intro_video_${SIZE}`;
    const isViewedIntroVideo = Account.isViewedVideo(VIEWED_VIDEO_KEY_LS);

    const goToBundle = () => {
        if (isHiddenProductsInfo) {
            return redirectToLogin();
        }
        const redirect = () => openBundleByUrl(state.currentPage?.name, selectedBundle.id);
        if (isMobileOrTabletWindow || isSafariBrowser) {
            redirect();
            return;
        }
        if (isLoadingIntroBundleVideo && !isViewedIntroVideo && state.clientSettings?.preset !== UserGraphicsQualityPresetTypes.LOW) {
            Account.markViewedVideo(VIEWED_VIDEO_KEY_LS);
            dispatch(appActions.startVideo({ name: 'bundle_preview', url: resourceIntroVideo[supportedIntroVideoUrl] }));
            setTimeout(redirect, 500);
        } else {
            redirect();
        }
    };

    const classes = classNames(styles.bundle, styles[SANTA_SIZE[props.groupName]], props.groupName);

    const bundle = { ...selectedBundle, quantityData: {} };

    const classesIdleVideo = classNames(styles.video, styles.animationIdle, {
        [styles.visible]: !_isDisabledAnimation && isLoadingResources,
    });

    const classesHoverVideo = classNames(styles.video, styles.animationHover);

    const classesImage = classNames(styles.image, styles[SIZE], {
        [styles.hidden]: !_isDisabledAnimation && isLoadingResources,
    });

    const classesHoverImage = classNames(styles.image, styles[SIZE], styles.hover, {
        [styles.hidden]: !_isDisabledAnimation && isLoadingResources,
    });

    const startAnimation = () => {
        const idle = idleAnimationRef.current;
        const hover = hoverAnimationRef.current;
        idle.classList.add(styles.hiddenIdleAnimation);
        hover.classList.add(styles.visibleHoverAnimation);
        hover.play();
    };

    const stopAnimation = () => {
        const idle = idleAnimationRef.current;
        const hover = hoverAnimationRef.current;
        idle.classList.remove(styles.hiddenIdleAnimation);
        hover.classList.remove(styles.visibleHoverAnimation);
        hover.pause();
        hover.currentTime = 0;
    };

    const onMouseEnter = () => {
        if (isAvailableSoundEffect && !howl.playing()) {
            howl.volume(1);
            howl.play();
        }
        !_isDisabledAnimation && !isMobileOrTabletWindow && isLoadingResources && startAnimation();
    };

    const onMouseLeave = () => {
        !_isDisabledAnimation && !isMobileOrTabletWindow && isLoadingResources && stopAnimation();
    };

    preCacheImage.add(bundle.icons?.small, bundle.icons?.confirmationBackground);

    return (
        <div className={classes} onClick={goToBundle} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={styles.imageContainer}>
                <img className={classesImage} src={getContainerIdleImage(SIZE, isCn, !_isGoldShowcase)} alt={bundle.title} />
                <img className={classesHoverImage} src={getContainerHoverImage(SIZE, isCn, !_isGoldShowcase)} alt={bundle.title} />
                {!isMobileOrTabletWindow && (
                    <>
                        <VideoElement className={classesIdleVideo} autoPlay={true} src={resourcesMap[animationsMap.idle]} ref={idleAnimationRef} />
                        <VideoElement className={classesHoverVideo} autoPlay={false} src={resourcesMap[animationsMap.hover]} ref={hoverAnimationRef} />
                    </>
                )}
                {!isCn && eventumAmount > 0 && (
                    <DivTooltip
                        className={styles.bonus}
                        tooltipBody={
                            <DefaultTooltip
                                text={interpolate(
                                    ngettext(
                                        'Приобретите эти контейнеры и получите {amount} рождественский жетон в качестве бонуса',
                                        'Приобретите эти контейнеры и получите {amount} рождественских жетона в качестве бонуса',
                                        eventumAmount,
                                    ),
                                    {
                                        amount: eventumAmount,
                                    },
                                )}
                            />
                        }
                    >
                        <span className={styles.bonusTitle}>
                            <AnimatedNumber value={eventumAmount} duration={0.5} formatter={(n) => interpolate(t('+{amount}'), { amount: n })} />
                        </span>
                    </DivTooltip>
                )}
            </div>
            {!isHiddenProductsInfo && (
                <div className={styles.infoContainer}>
                    <div className={styles.info}>
                        <div className={styles.title}>{selectedBundle.title}</div>
                    </div>
                    {currentGroupsBundles.length > 1 && (
                        <div className={styles.amount}>
                            <BundleAmount
                                bundles={currentGroupsBundles}
                                selectedBundleId={selectedBundle.id}
                                onChange={(bundle) => {
                                    updateSelectedBundle(bundle);
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.priceWrapper}>
                        <PriceWrapper
                            bundle={bundle}
                            discountClassName={styles.discount}
                            withoutAnimation={false}
                            wrapperClassName={styles.pricesWrapper}
                            priceClassName={classNames({
                                [styles.price]: !_isGoldShowcase,
                            })}
                        />
                    </div>
                    <div className={styles.purchaseWrapper}>
                        <div className={styles.purchase}>
                            {bundle.productCode ? (
                                <>
                                    {bundle.originalProduct?.isPurchasable ? (
                                        <RealPurchaseButton bundle={selectedBundle} buttonClassName={styles.button} purchaseButtonTheme={PurchaseButtonTheme.cookie} />
                                    ) : isCn ? (
                                        <Attention label={t("You've exceeded your daily limit of purchase. Limit is being reset daily at 08:00.")} className={styles.limitAttention} />
                                    ) : null}
                                </>
                            ) : (
                                <PurchaseButton
                                    bundle={selectedBundle}
                                    buttonClassName={styles.button}
                                    shortageClassName={styles.shortageClassName}
                                    classNameFastGoldLink={styles.fastGoldWrapper}
                                    classNameChinaFastGoldLink={styles.fastGoldWrapper}
                                    purchaseButtonTheme={PurchaseButtonTheme.cookie}
                                    isForceRenderPurchaseButton={true}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default SantaBundle;
