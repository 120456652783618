import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { armoryState, csrf, settings } from '~/utils/settings';
import client, { Client, VORTEX_ENDPOINT } from '~/client';
import gameVersion from '~/queries/version';
import { logError, logInfo } from '~/utils/logging';
import wowsEntities from '@wg/wows-entities';
import VortexDataStorage from '~/core/VortexDataStorage';
import { get } from '~/utils/ajax';
import { prepareSubCategories } from '~/utils/category';
import Account from '~/account/Account';
import store from '~/Store';
import { getStepsFromGuide } from '~/components/WelcomePage/steps';
import { preloadCategoryBackgrounds } from '~/utils/preCacheImage';
import { setFiltersInfo } from '~/settings/filtersMap';
import assets from '~/queries/assets';
import { getAllItemsCd, isDisplayRestricted } from '~/utils/bundles';
import { items as ITEMS } from '@wg/wows-entities/const';
import { flat, isInGameBrowser } from '~/utils/utils';
import { default as queryNations } from '~/queries/nations';
import { default as queryTypes } from '~/queries/types';
import { getAvailableGifts } from '~/api/gifts';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { preloadVideo } from '~/Layouts/Themes/SantaPage/preload';
import { getClientSettings } from '@wg/web2clientapi/browser/getClientSettings';
import { isNeedToShowWelcomeGiftScreen } from '~/utils/gift';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { accountActions } from '~/Store/accountSlice';
import { appActions } from '~/Store/appSlice';

type BUNDLE_DESCRIPTIONS_TYPE = Pick<IBundle, 'description' | 'descriptionAdditional'>;

export default class ArmoryPreloader extends AbstractPreloader {
    constructor(
        private user: IAccount = window.metashop.state?.account,
        private version: string = null,
        private isVortexRequestNeeded: boolean = true,
        private bundles: IBundleList = window.metashop.state.content?.bundles,
        private isVortexIsUnavailable: boolean = false,
        private requests: Promise<any>[] = [],
        private vortexData: any = {},
    ) {
        super();
    }

    async load(): Promise<any> {
        // todo: add retries
        csrf().catch(() => {
            logInfo('request to csrf was failed');
        });

        try {
            await this.initWowsEntities().catch((e) => {
                logError('Error during Wows entities init', e);
            });
            let gameVersion = wowsEntities.settings.gameVersion;
            if (!gameVersion) gameVersion = await this.fetchGameVersion(); // fallback if something went wrong in WE
            if (!gameVersion) throw 'No game version';
            this.version = gameVersion;
            window.metashop.settings.version = this.version;
            this.setVortexRequestNeededFlag();
        } catch (e) {
            this.isVortexIsUnavailable = true;
            logError('vortex is unavailable');
        }

        await this.prepareBundles();
        return Promise.resolve(1);
    }

    private async fetchGameVersion() {
        const versionClient = new Client(VORTEX_ENDPOINT.version);
        const response = await versionClient.get().query({ query: gameVersion });
        return response?.data?.version;
    }

    private async initWowsEntities() {
        return wowsEntities
            .init({
                vortexBaseUrl: settings.urls.vortex,
                languageCode: settings.languageCode,
            })
            .then(() => {
                wowsEntities.warmingUp([ITEMS.VEHICLES, ITEMS.CREWS]);
            });
    }

    private setVortexRequestNeededFlag() {
        this.isVortexRequestNeeded = !(VortexDataStorage.isDataRelevant(this.version, settings.languageCode) && VortexDataStorage.hasData());
    }

    private async prepareBundles() {
        const bundlesCount = Object.keys(this.bundles).length;

        if (this.isVortexIsUnavailable) {
            this.initApp({ data: this.bundles });
            return Promise.resolve(1);
        }

        if (!VortexDataStorage.isEqualsCountBundles(bundlesCount)) {
            VortexDataStorage.updateCountBundles(bundlesCount);
            this.isVortexRequestNeeded = true;
        }

        if (this.isVortexRequestNeeded) {
            await this.loadVortexData();
        } else {
            this.readDataFromLocalStorage();
        }

        return Promise.resolve(1);
    }

    private async initApp(response: any) {
        prepareSubCategories();

        if (this.user) {
            store.dispatch(accountActions.initAccount());
            Account.loadAccountInventoryFromVrtx();
        }

        store.dispatch(
            appActions.init({
                response,
                deniedBundlesByUniqueItems: Account.getDeniedBundlesByUniqueItems(),
                purchasedLimitedBundles: Account.getPurchasedLimitedBundles(),
            }),
        );

        this.postInitialisation();

        this.getUserGraphicsSettings();
    }

    private postInitialisation() {
        const { bundles, categories } = store.getState().app;
        if (this.user) {
            store.dispatch(accountActions.initGuideSteps(getStepsFromGuide()));
            store.dispatch(accountActions.setCoupons({ coupons: armoryState.content?.coupons, categories }));
        }
        if (bundles && categories && !!Account.getAccount()?.id) {
            store.dispatch(accountActions.setNotifications({ notifications: armoryState.content.notifications as INotification[], categories, bundles }));
        }
        preloadCategoryBackgrounds();

        this.loadBundleDescription();

        this.checkGifts();

        preloadVideo();
    }

    private async loadBundleDescription() {
        const response: { data: Record<number, BUNDLE_DESCRIPTIONS_TYPE> } = await get(settings.urls.getBundleDescriptions);
        if (!response || !response.data) {
            return;
        }
        const bundles: Record<string, IBundle> = store.getState().app.bundles;
        const merge = (bundleId: string | number, bundle: BUNDLE_DESCRIPTIONS_TYPE) => {
            return Object.assign(
                {},
                bundle,
                response.data[+bundleId] || {
                    description: null,
                    descriptionAdditional: null,
                },
            );
        };

        const updatedBundles = structuredClone(bundles);

        for (const [id, bundleData] of Object.entries(updatedBundles)) {
            if (Array.isArray(bundleData.randomBundleChildren)) {
                const updatedChildren = bundleData.randomBundleChildren.map((childBundle) => {
                    return merge(childBundle.id, childBundle as BUNDLE_DESCRIPTIONS_TYPE);
                });
                bundleData.randomBundleChildren = updatedChildren as IRandomBundleChild[];
            }
            updatedBundles[id] = merge(id, bundleData) as IBundle;
        }

        store.dispatch(appActions.updateBundleList(updatedBundles));
    }

    private readDataFromLocalStorage() {
        this.vortexData = VortexDataStorage.getAll();

        const response = {
            data: this.bundles,
            vehicleTypes: this.vortexData.data.class || [],
            nations: this.vortexData.data.nation || [],
            currencies: this.vortexData.data.currency || [],
        };

        setFiltersInfo().then(async () => {
            try {
                this.initApp(response);
            } catch (e) {
                this.requests = [];
                this.isVortexRequestNeeded = true;
                await this.loadVortexData();
            }
        });
    }

    private async loadVortexData() {
        this.requests.push(
            client.get().query({
                query: queryNations,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: queryTypes,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: assets,
                variables: {
                    lang: settings.languageCode,
                },
            }),
        );

        const bundleIdsByCategories = getAllItemsCd(this.bundles);
        const [nations, vehicleTypes, currencies] = await Promise.all(this.requests);

        const promiseRequestsByCategory: any = {};

        const dataBaseData: any = {
            lang: settings.languageCode,
            version: this.version,
            data: {
                class: vehicleTypes.data.vehicleTypes,
                nation: nations.data.nations,
                currency: currencies.data.currencies,
            },
        };

        const response: any = {
            data: this.bundles,
            currencies: currencies.data.currencies,
            nations: nations.data.nations,
            vehicleTypes: vehicleTypes.data.vehicleTypes,
        };

        Object.keys(bundleIdsByCategories).forEach((key) => {
            if (!GRAPHQL_QUERIES_MAP[key]) {
                return;
            }

            const ids = [...bundleIdsByCategories[key]];

            if (key === ITEMS.VEHICLES) {
                ids.push(...(settings.stylePreviewDefaultShips || []));
            }

            ids.sort();

            promiseRequestsByCategory[key] = [
                client
                    .get()
                    .query({
                        query: GRAPHQL_QUERIES_MAP[key],
                        variables: {
                            ids,
                            lang: settings.languageCode,
                        },
                    })
                    .catch(() => {
                        logInfo('response not successful for item', key);
                    }),
            ];
        });

        const values: any = Object.values(promiseRequestsByCategory) || [];
        const vortexData = await Promise.all(flat(values));

        vortexData.forEach((vortexResponse: any) => {
            if (!vortexResponse) {
                return;
            }
            const vortexRequestName = Object.keys(vortexResponse.data)[0];
            if (dataBaseData.data[vortexRequestName]) {
                dataBaseData.data[vortexRequestName].push(...vortexResponse.data[vortexRequestName]);
            } else {
                Object.assign(dataBaseData.data, { ...vortexResponse.data });
            }
        });

        VortexDataStorage.add(dataBaseData);

        setFiltersInfo().then(() => {
            this.initApp(response);
        });
    }

    async checkGifts() {
        const response: IRequestGift = await getAvailableGifts();
        const gifts = response.data;

        if (!gifts.length) {
            return;
        }

        if (Account.getAccount()?.id) {
            const availableGifts = gifts.filter((gift) => {
                return !gift.isObtained && !isDisplayRestricted(gift as IDisplayRestrictedOptions);
            });
            store.dispatch(accountActions.setGifts(availableGifts));
        } else if (isNeedToShowWelcomeGiftScreen(gifts?.[0]?.name)) {
            store.dispatch(accountActions.setGifts(gifts));
        }
    }

    async getUserGraphicsSettings() {
        if (!isInGameBrowser) {
            return;
        }

        try {
            const response = await getClientSettings();
            response.data && store.dispatch(accountActions.setClientSettings(response.data));
            dwhExport.send(DWH_EVENTS.USER_GRAPHICS_PRESET, {
                preset: response.data.preset,
                web_browser_hardware_acceleration: response.data.settings.web_browser_hardware_acceleration,
            });
        } catch (e) {
            logInfo('Graphics settings was failed');
        }
    }
}
