import classNames from 'classnames';
import styles from './SantaBundleBackground.scss';
import { isMobileOrTabletWindow } from '~/utils/utils';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import equal from 'fast-deep-equal/react';
import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getClientSettings';
import { RootState, useAppSelector } from '~/Store';

interface ISantaBundleBackground {
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        isTrusted: state.app.isTrusted,
        clientSettings: state.account.clientSettings,
        categories: state.app.categories,
        currentPage: state.app.currentPage,
    };
};

const SantaBundleBackground = ({ bundle }: ISantaBundleBackground) => {
    const state = useAppSelector(stateSelector, equal);
    const isAvailableSoundEffect = useAvailableSound(state.categories[state.currentPage?.name], bundle);

    if (isMobileOrTabletWindow) {
        return null;
    }

    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }

    const isLowGraphics = state.clientSettings?.preset === UserGraphicsQualityPresetTypes.LOW;

    return (
        <div className={styles.wrapper}>
            <VideoBackground
                key={bundle.id}
                poster={bundle.icons.innerBackground}
                video={isLowGraphics ? null : bundle.videoBackground}
                className={styles.videoBackgroundWrapper}
                posterClassName={classNames(styles.background)}
                videoClassName={styles.videoBackground}
                muted={muted}
                volume={0.5}
            ></VideoBackground>
        </div>
    );
};

export default SantaBundleBackground;
