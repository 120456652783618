import { useParams } from 'react-router-dom';
import History from '~/utils/history';
import CategoryContainer from '~/containers/CategoryContainer/CategoryContainer';
import { urlsMap } from '~/utils/menu';
import { routingStateSelector } from '~/routing/routingState';
import equal from 'fast-deep-equal/react';
import { isCategoryDisabledForUser } from '~/routing/helpers';
import { useAppSelector } from '~/Store';

export default function CategoryRouter() {
    const { categoryName, id } = useParams();
    const { categories, menu } = useAppSelector(routingStateSelector, equal);

    if (isCategoryDisabledForUser(categoryName, id, categories)) {
        History.navigate(urlsMap.home, { replace: true });
        return null;
    }

    if (menu[categoryName] && menu[categoryName].middleware !== undefined && !menu[categoryName].middleware()) {
        return null;
    }

    return <CategoryContainer params={{ categoryName, id }} />;
}
