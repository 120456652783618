import { PricesMapType, getBundleCurrenciesMap, getDefaultExchangeCurrency, getExchangeRate, getPurchaseExchangeRates, getSinglePurchaseMaxQuantity, hasUniqueItems } from '~/utils/bundles';
import { Currencies } from '@wg/wows-entities/const';
import { formatNumber, isEmptyObject } from '~/utils/utils';
import { interpolate, t } from '~/utils/localization';
import { getUserId } from '~/utils/settings';
import messageStyles from '~/components/Message/Message.scss';
import store from '~/Store';
import { chooseRandomBundle, purchase, purchaseSerialBundle } from '~/api/purchase';
import Account from '~/account/Account';
import { POPUPS_NAME } from '~/components/PopupManager';
import { openBundleByUrl, openCategoryByName } from '~/utils/category';
import dwhExport, { EventTypes } from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { canBoughtContainer } from '~/components/BundleLimitWidget/settings';
import { getAvailableCouponsFromBundle } from '~/utils/coupons';
import { syncAccountInfo } from '~/sync';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';
import { BUNDLE_TYPES } from '~/types/bundle';

export const calcMaxQuantityByBalance = (bundle: IBundle, balance: IBalance): number => {
    if (bundle.price === undefined) {
        return Infinity;
    }

    const maxQuantities: number[] = [];

    const pricesMap = getBundleCurrenciesMap(bundle);
    Object.keys(pricesMap).forEach((currency) => {
        if (isPriceOverdraftAvailable(currency)) {
            maxQuantities.push(Infinity);
            return;
        }

        const bundlePrice = pricesMap[currency];
        let currencyBalance = getBalanceByKey(balance, currency)?.value || 0;
        const defaultExchangeCurrency = getDefaultExchangeCurrency();
        const exchangeRate = getExchangeRate(currency);

        if (exchangeRate && defaultExchangeCurrency) {
            const exchangeCurrentBalance = getBalanceByKey(balance, defaultExchangeCurrency)?.value || 0;
            currencyBalance += Math.floor(exchangeCurrentBalance * exchangeRate);
        }

        maxQuantities.push(Math.floor(currencyBalance / bundlePrice));
    });

    return Math.min(...maxQuantities);
};

export const canChangeQuantity = (bundle: IBundle, maxQuantity: number = getSinglePurchaseMaxQuantity()) => {
    if (bundle.type === BUNDLE_TYPES.random) {
        return false;
    }

    return !bundle.allowCompensation && maxQuantity > 1 && !hasUniqueItems(bundle.entitlements) && bundle.limitedQuantity?.personalLimit !== 1;
};

export const canIncrementQuantity = (quantityData: IQuantityData, balance: IBalance, bundle?: IBundle, discount = 0): boolean => {
    const { final } = quantityData || calculateBundlePrices(bundle, balance, 1, discount);

    if (!final) {
        return false;
    }

    return Object.keys(final).every((currency: ICurrencyBalanceNames) => {
        const currentBalance = getBalanceByKey(balance, currency);
        return final[currency] <= currentBalance.value;
    });
};

export const isQuantityIncrementOverdraftAvailable = (prices: any): boolean => {
    return Object.keys(prices.final || {}).some((currency) => isPriceOverdraftAvailable(currency));
};

export const isPriceOverdraftAvailable = (currency: string): boolean => {
    return currency === Currencies.GOLD;
};

export const calculatePriceWithProgressivePurchase = (pricesMap: PricesMapType, balance: IBalance, quantity = 1, progressiveDiscount?: DiscountDiapason) => {
    const prices: any = {
        initial: {},
        withDiscount: {},
        final: {},
        lack: {},
    };

    Object.keys(pricesMap).forEach((currency) => {
        const value = pricesMap[currency];
        const currentBalance = getBalanceByKey(balance, currency);
        prices.initial[currency] = value * quantity;
        if (progressiveDiscount.discount) {
            prices.withDiscount[currency] = progressiveDiscount.price * quantity;
            prices.final[currency] = prices.withDiscount[currency];
        } else {
            prices.final[currency] = prices.initial[currency];
        }
        const lack = prices.final[currency] - currentBalance.value;
        if (lack > 0) {
            prices.lack[currency] = lack;
        }
    });

    return prices;
};

export const calculateBundlePrices = (bundle: IBundle, balance: IBalance, quantity = 1, discount = 0, progressiveDiscount?: DiscountDiapason) => {
    const pricesMap = getBundleCurrenciesMap(bundle);

    const isAvailableProgressivePurchase = progressiveDiscount?.discount && quantity > 1;
    if (isAvailableProgressivePurchase) {
        return calculatePriceWithProgressivePurchase(pricesMap, balance, quantity, progressiveDiscount);
    }

    return calculatePrice(pricesMap, balance, quantity, discount);
};

export const calculatePrice = (pricesMap: PricesMapType, balance: IBalance, quantity = 1, discount = 0) => {
    const prices: IQuantityData = {
        initial: {},
        withDiscount: {},
        final: {},
        lack: {},
    };

    if (!balance || isEmptyObject(pricesMap)) {
        return prices;
    }

    Object.keys(pricesMap).forEach((currency) => {
        const bundlePrice = pricesMap[currency];

        prices.initial = {
            ...prices.initial,
            [currency]: bundlePrice * quantity,
        };

        prices.withDiscount = Object.assign(prices.withDiscount, {
            [currency]: getPriceWithDiscount(prices.initial[currency], discount),
        });

        prices.final = {
            ...prices.final,
            [currency]: prices.withDiscount[currency],
        };

        const lack: Record<string, number> = {};
        const currentBalance = getBalanceByKey(balance, currency);
        const diff = prices.final[currency] - currentBalance.value;
        if (diff > 0) {
            lack[currency] = diff;
        }

        if (lack[currency]) {
            prices.lack = {
                ...prices.lack,
                ...lack,
            };
            const defaultExchangeCurrency = getDefaultExchangeCurrency();

            if (!!defaultExchangeCurrency) {
                const exchangeRate = getExchangeRate(currency);
                if (exchangeRate === undefined) {
                    return prices;
                }

                const additive = Math.ceil(lack[currency] / exchangeRate);
                const exchangeCurrentBalance = getBalanceByKey(balance, defaultExchangeCurrency);
                if (additive > exchangeCurrentBalance.value) {
                    return prices;
                }

                delete prices.lack[currency];
                prices.final = {
                    ...prices.final,
                    [defaultExchangeCurrency]: additive,
                    [currency]: prices.final[currency] - additive * exchangeRate,
                };
            }
        }
    });

    return prices;
};

export const getPriceWithDiscount = (price: number, discount: number) => {
    return Math.floor(price - price * discount);
};

export const isEnoughCurrency = (balance: IBalance, bundle: IBundle, coupon?: ICoupon | null, isActiveCoupon?: boolean): boolean => {
    const [currency, price] = [bundle.currency, bundle.price as number];
    const currentBalance = getBalanceByKey(balance, currency);

    if (coupon && isActiveCoupon) {
        const newPrice = getPriceWithDiscount(price, coupon.discount);

        return currentBalance.value >= newPrice;
    }

    return currentBalance.value >= price;
};

export const isEnoughCurrencyWithBestCoupon = (balance: IBalance, bundle: IBundle, coupons: ICoupon[] = []): boolean => {
    return (
        !isEmptyObject(balance) &&
        getAvailableCouponsFromBundle(coupons, bundle).some((coupon) => {
            const shortage = calculateBundlePrices(bundle, balance, 1, coupon.discount)?.lack || {};
            return isEmptyObject(shortage);
        })
    );
};

export const getBalanceByKey = (balance: IBalance, currency: string): IBalanceData => {
    const mock = {
        currency: currency,
        value: 0,
        paidPart: 0,
    };

    if (isEmptyObject(balance)) {
        return mock;
    }

    const currencyBalance = Object.values(balance).filter((balance: IBalanceData) => {
        return balance.currency === currency;
    })[0];

    return currencyBalance || mock;
};

export const getNeededAmount = (balance: IBalance, bundle: IBundle, discount = 0, isActiveCoupon = false): { amount: number; currency: string } => {
    const [currency, price] = [bundle.currency, bundle.price as number];
    const currentBalance = getBalanceByKey(balance, currency);

    if (discount && isActiveCoupon) {
        const newPrice = getPriceWithDiscount(price, discount);

        return {
            amount: newPrice - currentBalance.value,
            currency,
        };
    }

    return {
        amount: price - currentBalance.value,
        currency,
    };
};

export const isDisabledForPurchase = (
    purchasedLimitedBundles: {
        [key: string]: number;
    },
    deniedBundlesByUniqueItems: number[],
    bundle: IBundle,
): boolean => {
    if (purchasedLimitedBundles[bundle.id] >= bundle.limitedQuantity?.personalLimit) {
        return true;
    }

    if (bundle.serialPurchase) {
        if (!bundle.dependentBundle) {
            return false;
        }

        return !Object.keys(purchasedLimitedBundles).includes(bundle.dependentBundle.toString());
    }

    return deniedBundlesByUniqueItems.includes(bundle.id);
};

export const isDisabledSerialBundle = (purchasedLimitedBundles: AccountPurchasedLimitedBundles, bundle: IBundle) => {
    if (!bundle.dependentBundle) {
        return false;
    }

    return !Object.keys(purchasedLimitedBundles).includes(bundle.dependentBundle.toString());
};

export const getNextAvailableFromPurchaseSerialBundle = (purchasedLimitedBundles: AccountPurchasedLimitedBundles, serialSequence: number[]): number | null => {
    const keys = Object.keys(purchasedLimitedBundles);
    return serialSequence.reduce((id: number, bundleId: number) => {
        if (!keys.includes(bundleId.toString()) && !id) {
            id = bundleId;
        }
        return id;
    }, null);
};

export const getSerialPurchaseBlockedLabel = (bundleSerialIndex: number) => {
    return bundleSerialIndex === 1 ? t('Необходимо купить предыдущий набор') : t('Необходимо купить предыдущие наборы');
};

export const isAlreadyPurchased = (
    purchasedLimitedBundles: {
        [key: string]: number;
    },
    deniedBundlesByUniqueItems: number[],
    bundle: IBundle,
): boolean => {
    if (!getUserId()) {
        return false;
    }

    return purchasedLimitedBundles?.[bundle.id] >= bundle.limitedQuantity?.personalLimit || deniedBundlesByUniqueItems.includes(bundle.id);
};

export const getHtmlFinalCurrencies = (bundle: IBundle, options?: { currency: string; price: number }) => {
    if (!bundle.quantityData) {
        return null;
    }

    const keys = Object.keys(bundle.quantityData.final);
    let text = null;

    if (keys.length >= 1) {
        const currenciesHtml = keys.reduce((arr: string[], currency: ICurrencyBalanceNames) => {
            let amount = formatNumber(bundle.quantityData.final[currency]).split(',').join(' ');
            if (options && options.price && currency === options.currency) {
                amount = formatNumber(options.price);
            }

            if (amount.toString().trim() === '0') {
                return arr;
            }

            arr.push(`<span class="icon-${currency} size-25">${amount}</span>`);

            return arr;
        }, []);

        text = currenciesHtml.join(`<span class="delimiter-currencies">+</span>`);
    }

    return text;
};

export const getExchangeMessage = (bundle: IBundle): string => {
    const message = interpolate(t('{open}При покупке будет произведен обмен стали на уголь в размере:{close} '), {
        open: `<span class="${messageStyles.orangeText}">`,
        close: `</span>`,
    });

    return message + getHtmlFinalCurrencies(bundle);
};

export const getExchangeFullMessage = (quantityData: IQuantityData): string => {
    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    const message = interpolate(
        t(`У вас недостаточно угля для покупки данного товара. Недостающее количество будет заменено сталью: {open}{steel}{close}. {div_open}Курс стали к углю: {coal} = {exchange}{div_close}`),
        {
            open: `<span class="${messageStyles.whiteText}">`,
            close: `</span>`,
            steel: `<span class="armory-currency armory-currency__steel">${formatNumber(quantityData?.final?.steel || 0)}</span>`,
            coal: `<span class="armory-currency armory-currency__steel">1</span>`,
            exchange: `<span class="armory-currency armory-currency__coal">${exchangeToCurrency}</span>`,
            div_open: `<div class="inline-block">`,
            div_close: `</div>`,
        },
    );

    return message;
};

export const _purchaseRandomBundle = (bundle: IBundle, parentRandomBundle: IBundle, callback?: () => void, closeCallback?: () => void, withoutConfirmPopup = false) => {
    const { balance, restrictedLootboxesPurchaseCount } = store.getState().account;
    const errorPurchaseCode = canBoughtContainer(bundle, restrictedLootboxesPurchaseCount);
    const isEnoughCurrency = Account.isEnoughCurrency(balance, parentRandomBundle, null, null);

    if (Number(errorPurchaseCode) > 0 && isEnoughCurrency && withoutConfirmPopup) {
        callback?.();

        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.CONFIRM_PURCHASE,
                isVisible: false,
                data: {
                    bundleId: parentRandomBundle.id,
                    callback,
                    closeCallback,
                },
            }),
        );

        _purchase(bundle);

        return;
    }

    store.dispatch(
        appActions.changeVisibilityPopup({
            name: POPUPS_NAME.CONFIRM_PURCHASE,
            isVisible: true,
            data: {
                bundleId: parentRandomBundle.id,
                callback,
                closeCallback,
            },
        }),
    );
};

export const _chooseRandomBundle = (bundle: IBundle, callback?: () => void) => {
    store.dispatch(accountActions.addBundleToTransaction([bundle.id]));
    chooseRandomBundle(bundle.id).then((data) => {
        store.dispatch(accountActions.removeBundleFromTransaction(bundle.id));
        callback?.();
        store.dispatch(accountActions.openBundle(bundle.id));
        store.dispatch(accountActions.purchaseRandomBundle({ bundleId: bundle.id, newSelectedBundleId: data.id }));
        syncAccountInfo();
    });
};

export const _purchase = (bundle: IBundle, callback?: () => void) => {
    const bundleId = bundle.parentBundleId ?? bundle.id;
    const account = store.getState().account;
    const bundles = store.getState().app.bundles;
    const activeCoupon = Account.getActiveCoupon(account.activeCouponId, account.coupons);
    const quantity = bundle.quantityData?.quantity || 1;

    store.dispatch(accountActions.addBundleToTransaction([bundle.id]));
    store.dispatch(accountActions.setPurchasingBundleId(bundle.id));

    if (activeCoupon?.id) {
        window.metashop.state.content.newCouponsCount = null;
    }

    const purchaseMeta = {
        bundle_id: bundle.id,
        quantity: quantity,
        coupon_id: activeCoupon?.id,
    };

    const purchasePriceInfo = bundles[bundle.parentBundleId]?.quantityData?.final ?? bundle.quantityData?.final;
    purchase(bundleId.toString(), quantity, activeCoupon?.name, purchasePriceInfo)
        .then((data) => {
            callback?.();
            if (data.status !== 200) {
                store.dispatch(accountActions.removeBundleFromTransaction(bundle.id));
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.ERROR_PURCHASE,
                        isVisible: true,
                        data: {
                            bundleId: bundle.id,
                        },
                        forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE],
                    }),
                );
                dwhExport.send(DWH_EVENTS.PURCHASE_REQUEST_ERROR, purchaseMeta, EventTypes.Bundle);
            } else {
                dwhExport.send(DWH_EVENTS.PURCHASE_REQUEST, purchaseMeta, EventTypes.Bundle);
            }
        })
        .catch(() => {
            store.dispatch(accountActions.removeBundleFromTransaction(bundle.id));
            store.dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.ERROR_PURCHASE,
                    isVisible: true,
                    data: {
                        bundleId: bundleId,
                    },
                    forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE],
                }),
            );
            dwhExport.send(DWH_EVENTS.PURCHASE_REQUEST_ERROR, purchaseMeta, EventTypes.Bundle);
        });
};

export const purchaseSerialSequence = (sequence: IBundle[], balance: IBalance) => {
    const bundleIds = sequence.map((bundle) => bundle.id);
    const body = sequence.map((bundle) => {
        return {
            id: bundle.id,
            price: calculateBundlePrices(bundle, balance).final,
        };
    });

    store.dispatch(accountActions.addBundleToTransaction(bundleIds));

    purchaseSerialBundle(body)
        .then((data) => {
            if (data.status !== 200) {
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.ERROR_PURCHASE,
                        isVisible: true,
                        data: {
                            bundleId: bundleIds[0],
                        },
                        forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE],
                    }),
                );
            }
        })
        .catch(() => {
            store.dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.ERROR_PURCHASE,
                    isVisible: true,
                    data: {
                        bundleId: bundleIds[0],
                    },
                    forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE],
                }),
            );
        });
};

export const purchaseBundleFromPopup = (bundle: IBundle, parentRandomBundle?: IBundle, callback?: () => void, closeCallback?: () => void, withoutConfirmPopup = false) => {
    if (parentRandomBundle) {
        _purchaseRandomBundle(bundle, parentRandomBundle, callback, closeCallback, withoutConfirmPopup);
        return;
    }

    store.dispatch(
        appActions.changeVisibilityPopup({
            name: POPUPS_NAME.CONFIRM_PURCHASE,
            isVisible: true,
            data: {
                bundleId: bundle.id,
                callback,
                closeCallback,
            },
        }),
    );
};

export const successPurchaseCallback = (bundle: IBundle) => {
    const app = store.getState().app;
    const hasUniqueItems = bundle.entitlements.some((item) => item.isUnique);
    if (bundle.serialPurchase) {
        const nextBundle = app.bundles[+bundle.nextBundle];
        if (nextBundle) {
            openBundleByUrl(app.currentPage?.name, nextBundle.id);
        }
    } else if (hasUniqueItems && !bundle.isRandom) {
        openCategoryByName(app.currentPage?.name);
    }
};
